import React from 'react';

import { Markdown } from '../../../common/markdown';

import { Item, Title, Text } from './itemValuesList.styles';
import { ItemValuesListProps } from './itemValuesList.types';

export const ItemValuesList = ({ title, text }: ItemValuesListProps) => {
  return (
    <Item>
      <Title>{title}</Title>
      <Text>
        <Markdown text={text} />
      </Text>
    </Item>
  );
};
