import React from 'react';

import { useFilters } from '../../../hooks/useFilters';
import { Container } from '../../common/container';
import { Filters } from '../../common/filters';

import { ItemNews } from './itemNews';
import { FilterWrapper, NewsList, Title } from './moduleNews.styles';
import { ModuleNewsProps } from './moduleNews.types';

export const ModuleNews = ({ items, title }: ModuleNewsProps) => {
  const { filters, activeFilter, filteredItems, chageFilter } = useFilters(
    'All',
    items,
  );

  return (
    <>
      <Container>
        <Title>{title}</Title>
      </Container>
      <Container>
        <FilterWrapper>
          <Filters
            isExpandable
            activeFilter={activeFilter}
            filters={filters}
            onChange={chageFilter}
          />
        </FilterWrapper>
        <NewsList>
          {filteredItems.map((item) => (
            <ItemNews {...item} />
          ))}
        </NewsList>
      </Container>
    </>
  );
};
