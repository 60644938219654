import React from 'react';

import { Media } from '../../common/media';

import { MediaWrapper, Title, Text } from './moduleHero.styles';
import { ModuleHeroProps } from './moduleHero.types';

export const ModuleHero = ({ title, heroMedia }: ModuleHeroProps) => {
  return (
    <MediaWrapper>
      <Media media={heroMedia} noRadius isHero />
      <Title>
        <Text>{title}</Text>
      </Title>
    </MediaWrapper>
  );
};
