import React from 'react';
import { ThemeProvider } from 'styled-components';

import { plainTextToHTML } from '../../../common/string';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { Media } from '../../common/media';
import { ReadMore } from '../../common/readMore';

import {
  Content,
  Heading,
  Text,
  MediaWrapper,
} from './moduleTextAndImage.styles';
import { ModuleTextAndImageProps } from './moduleTextAndImage.types';

export const ModuleTextAndImage = ({
  theme,
  heading,
  text,
  _textAndImageMedia,
  links,
  hasSpace,
  mediaControls,
}: ModuleTextAndImageProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
      >
        <Content>
          {heading && <Heading>{heading}</Heading>}
          {text && <Text>{plainTextToHTML(text)}</Text>}
          {links.length ? (
            <ReadMore links={[links[0]]} gutterTop gutterBottom />
          ) : (
            ''
          )}
        </Content>
        <MediaWrapper>
          <Media media={_textAndImageMedia} mediaControls={mediaControls} />
        </MediaWrapper>
      </Container>
    </ThemeProvider>
  );
};
