import styled from 'styled-components';

import { getColor, Color } from '../../../theme/color';
import {
  getMediaQuery,
  getUnitAsPixels,
  ScreenSize,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 7 / span 6;
  }
`;

export const Column = styled.div`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: ${getUnitAsPixels({ multiplier: 4 })};
  padding-bottom: ${getUnitAsPixels({ multiplier: 5 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: 50%;
    padding-bottom: ${getUnitAsPixels({ multiplier: 7 })};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
`;

export const Address = styled(Text)`
  margin-bottom: ${getUnitAsPixels({ multiplier: 4 })};
`;

export const Email = styled.a`
  color: ${getColor(Color.BLACK)};
  text-decoration: none;
  ${getTypography(Type.BODY_REGULAR)}
  word-break: break-all;

  &:hover {
    color: ${getColor(Color.RED)};
  }
`;

export const EmailArrow = styled.span`
  color: ${getColor(Color.RED)};
`;
