import styled from 'styled-components';

import {
  getMediaQuery,
  ScreenSize,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Content = styled.div`
  grid-column: span 12;
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: 7 / span 6;
    margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}

  & > p:not(:last-child) {
    margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;
