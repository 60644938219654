import styled from 'styled-components';

import { getColor, Color } from '../../../../theme/color';

export const ImageWrapper = styled.div`
  position: absolute;
  max-width: 50%;
  max-height: 50%;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  div[data-gatsby-image-wrapper] {
    display: flex;
    justify-content: center;
    height: 100%;
    margin-bottom: 0;

    > img {
      object-fit: contain !important;
      top: 50%;
      left: 50%;
    }
  }

  img {
    object-fit: contain;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    position: absolute;
  }
`;

export const Link = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease;

  &:hover {
    border-bottom: 1px solid ${getColor(Color.RED)};
  }
`;

export const LinkLabel = styled.span`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;
