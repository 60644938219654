import styled from 'styled-components';

import { getMediaQuery, ScreenSize } from '../../../theme/layout';

export const ValuesList = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 5 / span 8;
  }
`;
