import styled from 'styled-components';

import Arrow from '../../../assets/icons/arrow.inline.svg';
import { Color, getColor } from '../../../theme/color';
import {
  getMediaQuery,
  ScreenSize,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Link = styled.a<{
  gutterBottom: boolean;
  gutterTop: boolean;
  asButton: boolean;
}>`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  ${getTypography(Type.BODY_SMALL)};
  text-decoration: none;
  color: ${getColor(Color.BLACK)};
  margin-top: ${({ gutterTop }) =>
    gutterTop ? `${getUnitAsPixels({ multiplier: 2 })}` : `none`};
  margin-bottom: ${({ gutterBottom }) =>
    gutterBottom ? `${getUnitAsPixels({ multiplier: 3 })}` : `none`};
  padding: ${({ asButton }) =>
    asButton ? `${getUnitAsPixels({ multiplier: 2 })}` : 0};
  background-color: ${({ asButton }) =>
    asButton ? `${getColor(Color.GREY_01)}` : 'transparent'};

  &:hover {
    color: ${({ asButton }) =>
      asButton ? `${getColor(Color.BLACK)}` : `${getColor(Color.RED)}`};
    box-shadow: inset 0px -1px 0px 0px ${getColor(Color.RED)};

    & path {
      fill: ${getColor(Color.RED)};
    }
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-top: ${({ gutterTop }) =>
      gutterTop ? `${getUnitAsPixels({ multiplier: 3 })}` : `none`};
    margin-bottom: ${({ gutterBottom }) =>
      gutterBottom ? `${getUnitAsPixels({ multiplier: 5 })}` : `none`};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-top: ${({ gutterTop }) =>
      gutterTop ? `${getUnitAsPixels({ multiplier: 3 })}` : `none`};
    margin-bottom: 0;
  }
`;

export const RedArrowIcon = styled(Arrow)`
  width: 8px;
  height: auto;
  margin-left: ${getUnitAsPixels({ multiplier: 0.5 })};
  margin-top: ${getUnitAsPixels({ multiplier: 0.1 })};

  & path {
    fill: ${getColor(Color.RED)};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: 10px;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    width: 12px;
    margin-left: ${getUnitAsPixels()};
  }
`;

export const BlackArrowIcon = styled(Arrow)`
  width: 16px;
  height: 14px;
  margin-left: ${getUnitAsPixels({ multiplier: 2 })};
  margin-top: ${getUnitAsPixels({ multiplier: 0.3 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: 16px;
    height: 14px;
    margin-left: ${getUnitAsPixels({ multiplier: 2 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    width: 20px;
    height: 18px;
    margin-left: ${getUnitAsPixels({ multiplier: 4 })};
  }
`;
