import styled from 'styled-components';

import { getColor, Color } from '../../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  getUnitAsPixels,
  getMediaQuery,
  ScreenSize,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Container = styled.div`
  position: relative;
  display: grid;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.SMALL,
    bottom: VerticalSpacingVariant.SMALL,
  })}
  border-top: 1px solid ${getColor(Color.GREY_02)};
  border-bottom: 1px solid ${getColor(Color.GREY_02)};
  margin-bottom: -1px;

  &:hover {
    border-top: 1px solid ${getColor(Color.RED)};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-template-columns: ${({ isDate }: { isDate: boolean }) =>
      isDate ? 'calc(25% + 5px) calc(75% - 5px)' : '100%'};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FileNameWrapper = styled.div`
  padding-right: ${getUnitAsPixels({ multiplier: 2 })};

  ${Container}:hover & {
    & > :first-child {
      color: ${getColor(Color.RED)};
    }
  }
`;

export const FileName = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.BLACK)};
`;

export const DownloadIconWrapper = styled.div`
  cursor: pointer;
  margin-top: ${getUnitAsPixels({ multiplier: 0.5 })};

  & > svg {
    ${getTypography(Type.DOWNLOAD_ICON)};

    ${Container}:hover & {
      path {
        stroke: ${getColor(Color.RED)};
      }
    }
  }
`;

export const DownloadLink = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels()};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;

export const Date = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
  white-space: nowrap;
  margin-bottom: ${getUnitAsPixels({ multiplier: 5 })};

  ${Container}:hover & {
    color: ${getColor(Color.RED)};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 7 })};
  }
`;
