import React from 'react';
import { ThemeProvider } from 'styled-components';

import { plainTextToHTML } from '../../../common/string';
import { getTheme } from '../../../theme/color';
import { Container } from '../../common/container';
import { ReadMore } from '../../common/readMore';

import { Content, Text } from './moduleText.styles';
import { ModuleTextProps } from './moduleText.types';

export const ModuleText = ({ theme, text, link }: ModuleTextProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container>
        <Content>
          {text && <Text>{plainTextToHTML(text)}</Text>}
          {link.length ? <ReadMore links={[link[0]]} gutterTop /> : ''}
        </Content>
      </Container>
    </ThemeProvider>
  );
};
