import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  getMediaQuery,
  getUnitAsPixels,
  ScreenSize,
  screenSizeMax,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const TitleBlock = styled.div`
  grid-column: span 12;
  position: relative;
  margin-top: -${getUnitAsPixels({ multiplier: 5 })};
  height: 100vh;
  ${({ isImage }: { isImage: boolean }) =>
    isImage &&
    `
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        mix-blend-mode: normal;
        transform: rotate(-180deg);
        z-index: 2;
      }
  `}

  div {
    color: ${({ isImage }: { isImage: boolean }) =>
      isImage ? getColor(Color.WHITE) : getColor(Color.BLACK)};
  }

  & > div[data-gatsby-image-wrapper] {
    height: 100%;
    margin-bottom: 0;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-top: -${getUnitAsPixels({ multiplier: 6 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-top: -${getUnitAsPixels({ multiplier: 8 })};
  }
`;

export const ColorMask = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${getColor(Color.GREY_01)};
`;

export const TextTitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: ${screenSizeMax};
  height: 100%;
  padding: 0 ${getUnitAsPixels({ multiplier: 2 })}
    ${getUnitAsPixels({ multiplier: 2 })};
  margin: calc(-100vh) auto 0;
  z-index: 3;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin: calc(-100vh) auto 0;
    padding: 0 ${getUnitAsPixels({ multiplier: 3 })}
      ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const TextTitle = styled.div`
  position: sticky;
  bottom: ${getUnitAsPixels({ multiplier: 2 })};
  ${getTypography(Type.HEADING_HERO)}
  grid-column: span 12;
  letter-spacing: -0.03em;
  color: ${getColor(Color.WHITE)};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    bottom: ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const Title = styled.h1`
  ${getTypography(Type.HEADING_1)}
  letter-spacing: -0.01em;
  line-height: 1.3;

  ${getMediaQuery(ScreenSize.LARGE)} {
    letter-spacing: -0.04em;
  }
`;

export const SubTitle = styled.div`
  ${getTypography(Type.BODY_SMALL)}
  font-size: 14px;
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  & > span:first-child {
    margin-right: ${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    ${getTypography(Type.BODY_REGULAR)}
  }
`;
