import styled from 'styled-components';

import ItemClose from '../../../../../assets/icons/item_close.inline.svg';
import ItemOpen from '../../../../../assets/icons/item_open.inline.svg';
import { Color, getColor } from '../../../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  getMediaQuery,
  ScreenSize,
  getUnitAsPixels,
} from '../../../../../theme/layout';
import { getTypography, Type } from '../../../../../theme/typography';

export const Root = styled.div`
  ${getTypography(Type.LABEL)}
  border-top: 1px solid ${getColor(Color.GREY_02)};

  &:last-child {
    border-bottom: 1px solid ${getColor(Color.GREY_02)};
  }

  &:hover {
    border-top: 1px solid ${getColor(Color.RED)};
  }
`;

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding-right: 20px;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.SMALL,
    bottom: VerticalSpacingVariant.SMALL,
  })}
`;

export const Heading = styled.div`
  // width: 45%;
  width: 100%;
  ${getTypography(Type.BODY_REGULAR)}

  ${ItemRow}:hover & {
    color: ${getColor(Color.RED)};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: 45%;
  }
`;

export const Subheading = styled.div`
  // width: 45%;
  width: 100%;
  color: ${getColor(Color.GREY_03)};
  ${getTypography(Type.BODY_REGULAR)}

  ${ItemRow}:hover & {
    color: ${getColor(Color.RED)};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: 45%;
    // margin-right: ${getUnitAsPixels({ multiplier: 6 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    // margin-right: ${getUnitAsPixels({ multiplier: 10 })};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, {
    bottom: VerticalSpacingVariant.SMALL,
  })}
`;

export const ImageWrapper = styled.div`
  text-align: center;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, {
    bottom: VerticalSpacingVariant.SMALL,
  })}
`;

export const ItemOpenWrapper = styled.div`
  position: relative;
  ${getTypography(Type.ACCORDION_ICON)}
`;

export const ItemOpenIcon = styled(ItemOpen)`
  ${getTypography(Type.ACCORDION_ICON)}

  ${ItemRow}:hover & {
    transform: rotate(90deg);
    transition: transform 0.4s;

    & path {
      stroke: ${getColor(Color.RED)};
    }
  }
`;

export const ItemCloseIcon = styled(ItemClose)`
  ${getTypography(Type.ACCORDION_ICON)}

  ${ItemRow}:hover & {
    transform: rotate(180deg);
    transition: transform 0.4s;

    & path {
      stroke: ${getColor(Color.RED)};
    }
  }
`;
