import styled from 'styled-components';

import { getMediaQuery, ScreenSize } from '../../../../theme/layout';

export const Toggler = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 22px;
  right: 0;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    top: 32px;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    top: 30px;
  }
`;
