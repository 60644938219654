import { get } from 'lodash';
import React, { Fragment } from 'react';

import { getSchema } from '../../../common/schema';
import { SEO } from '../../common/seo';
import { BodyModular } from '../bodyModular';

export const PageLayout = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const versions = get(data, 'versions.nodes');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <BodyModular content={page.body} />
    </>
  );
};
