/* eslint-disable import/no-unresolved */
import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { getColor, Color } from '../../../theme/color';
import { getTypography, Type } from '../../../theme/typography';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Root = styled.div`
  position: relative;
`;

const Counter = styled.div`
  ${getTypography(Type.HEADING_2)}
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  color: ${getColor(Color.WHITE)};
  user-select: none;
  z-index: 10;
`;

interface Props {
  children: ReactNode;
  autoPlay?: boolean;
  autoPlayInterval?: number;
}

export const Gallery: React.FC<Props> = ({
  children,
  autoPlay,
  autoPlayInterval,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const imagesCount = React.Children.toArray(children).length;
  const swiper = useRef<SwiperClass | null>(null);

  return (
    <Root>
      <Swiper
        autoplay={
          autoPlay
            ? {
                delay: autoPlayInterval,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }
            : undefined
        }
        loop
        loopFillGroupWithBlank
        slidesPerView={1.25}
        spaceBetween={20}
        threshold={1}
        onSlideChange={(swiper) => {
          setActiveIndex(
            // @see activeIndex documentation notes
            (swiper.activeIndex + (swiper.loopedSlides ?? 0)) % imagesCount,
          );
        }}
        onClick={() => {
          swiper.current?.slideNext();
        }}
        onSwiper={(instance) => {
          swiper.current = instance;
        }}
        modules={[Autoplay, Pagination]}
      >
        {React.Children.map(children, (x) => (
          <SwiperSlide>{x}</SwiperSlide>
        ))}
      </Swiper>
      <Counter>
        {activeIndex + 1}/{imagesCount}
      </Counter>
    </Root>
  );
};
