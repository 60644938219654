import { Link } from 'gatsby';
import React from 'react';

import Download from '../../../assets/icons/download.inline.svg';
import { useDictionary } from '../../../context/dictionary';
import { Container } from '../../common/container';

import {
  Content,
  Social,
  SocialText,
  SocialLinks,
  PdfWrapper,
  TwitterIcon,
  FacebookIcon,
  EmailIcon,
} from './moduleArticleShareBlock.styles';
import { ModuleArticleShareBlockProps } from './moduleArticleShareBlock.types';

export const ModuleArticleShareBlock = ({
  email,
  twitter,
  facebook,
  pdf,
}: ModuleArticleShareBlockProps) => {
  const downloadLabel = useDictionary('newsArticleDownloadPdf');
  const shareLabel = useDictionary('newsArticleShareThisArticle');

  return (
    <Container>
      <Content>
        {pdf && (
          <PdfWrapper href={`${pdf.url}?dl=${pdf.filename}`} target="_blank">
            {downloadLabel} <Download />
          </PdfWrapper>
        )}
        <Social>
          <SocialText>{shareLabel}</SocialText>
          <SocialLinks>
            <Link to={twitter} target="_blank">
              <TwitterIcon />
            </Link>
            <Link to={facebook} target="_blank">
              <FacebookIcon />
            </Link>
            <Link to={email}>
              <EmailIcon />
            </Link>
          </SocialLinks>
        </Social>
      </Content>
    </Container>
  );
};
