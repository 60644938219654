import styled from 'styled-components';

import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Title = styled.h1`
  grid-column: span 12;
  ${getTypography(Type.HEADING_HERO)};
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.LARGE,
    bottom: VerticalSpacingVariant.LARGE,
  })}
`;

export const FilterWrapper = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 4;
  }
`;

export const NewsList = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: ${getUnitAsPixels({ multiplier: 2 })};
  row-gap: ${getUnitAsPixels({ multiplier: 2 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 8;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${getUnitAsPixels({ multiplier: 3 })};
    row-gap: ${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.MEDIUM, ScreenSize.LARGE)} {
    grid-column: span 12;
  }
`;
