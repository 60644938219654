import React from 'react';
import { ThemeProvider } from 'styled-components';

import { DatoCmsItemNumberedList } from '../../../common/types/graphql';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';

import { ItemNumberedList } from './itemNumberedList';
import { NumberedList } from './moduleNumberedList.styles';
import { ModuleNumberedListProps } from './moduleNumberedList.types';

export const ModuleNumberedList = ({
  theme,
  items,
  hasSpace,
}: ModuleNumberedListProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace
            ? VerticalSpacingVariant.SMALL
            : VerticalSpacingVariant.MEDIUM
        }
        spaceBottom={
          hasSpace
            ? VerticalSpacingVariant.SMALL
            : VerticalSpacingVariant.MEDIUM
        }
      >
        <NumberedList>
          {items.map((item: DatoCmsItemNumberedList, idx: number) => (
            <ItemNumberedList {...item} key={item.id} idx={idx} />
          ))}
        </NumberedList>
      </Container>
    </ThemeProvider>
  );
};
