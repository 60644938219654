import React, { useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import { useFilters } from '../../../hooks/useFilters';
import { useToggle } from '../../../hooks/useToggle';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { Filters } from '../../common/filters';
import { ShowMore } from '../../common/showMore';

import { ItemDownloadList } from './itemDownloadList';
import {
  DownloadList,
  Expanded,
  Col,
  ShowMoreContainer,
} from './moduleDownloadList.styles';
import { ModuleDownloadListProps } from './moduleDownloadList.types';

export const ModuleDownloadList = ({
  theme,
  items,
  collapsible,
  hasSpace,
}: ModuleDownloadListProps) => {
  const expandedOver = collapsible ? 8 : items.length;
  const isFilters = useMemo(
    () => items.some((item) => !!item?.category),
    [items],
  );
  const isDate = useMemo(() => items.some((item) => !!item?.date), [items]);
  const [isShowMore, toggleShowMore] = useToggle(false);
  const { filters, activeFilter, filteredItems, chageFilter } = useFilters(
    'All',
    items,
    'number',
  );

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
        spaceBottom={
          hasSpace
            ? VerticalSpacingVariant.SMALL
            : VerticalSpacingVariant.MEDIUM
        }
      >
        {isFilters && (
          <Col>
            <Filters
              filters={filters}
              activeFilter={activeFilter}
              onChange={chageFilter}
            />
          </Col>
        )}
        <DownloadList isDate={isDate} isFilters={isFilters}>
          {filteredItems.slice(0, expandedOver).map((item) => (
            <ItemDownloadList {...item} />
          ))}
          {isShowMore && (
            <Expanded>
              {filteredItems.slice(expandedOver).map((item) => (
                <ItemDownloadList {...item} />
              ))}
            </Expanded>
          )}
          <ShowMoreContainer isDate={isDate}>
            {filteredItems.length > expandedOver && (
              <ShowMore
                isShowMore={isShowMore}
                toggleShowMore={toggleShowMore}
              />
            )}
          </ShowMoreContainer>
        </DownloadList>
      </Container>
    </ThemeProvider>
  );
};
