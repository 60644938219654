import React from 'react';

import { Image } from '../image';
import { Video } from '../video';

import { MediaProps } from './media.types';

export const Media = ({
  className,
  media,
  noRadius,
  fullWidth,
  isHero = false,
  mediaControls,
}: MediaProps) => {
  return media.video ? (
    <Video
      src={media.video.mp4Url}
      className={className}
      noRadius={noRadius}
      fullWidth={fullWidth}
      isHero={isHero}
      mediaControls={mediaControls}
      autoPlay
      muted
      playsInline
    />
  ) : (
    <Image asset={media} noRadius={noRadius} />
  );
};
