import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React from 'react';

import { DatoCmsNewsArticle } from '../../../common/types/graphql';
import { useLocale } from '../../../context/locale';
import { Container } from '../../common/container';

import { ItemLatestNews } from './itemLatestNews';
import { ItemLatestNewsProps } from './itemLatestNews/itemLatestNews.types';
import { DesktopWrapper, MobileWrapper } from './moduleLatestNews.styles';
import { ModuleLatestNewsProps } from './moduleLatestNews.types';

export const ModuleLatestNews = ({ first = 3 }: ModuleLatestNewsProps) => {
  const data = useStaticQuery(graphql`
    query LatestNewsQuery {
      latestNews: allDatoCmsNewsArticle(
        filter: { slug: { ne: null } }
        sort: { order: DESC, fields: date }
      ) {
        nodes {
          locale
          id
          date
          description
          slug
          previewImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { crop: "focalpoint", fit: "crop", ar: "16:9" }
            )
          }
        }
      }
    }
  `);
  const locale = useLocale();
  const latestNews = get(data, 'latestNews.nodes')
    .filter((n: DatoCmsNewsArticle) => n.locale === locale)
    .slice(0, first);

  return (
    <>
      <DesktopWrapper>
        <Container>
          {latestNews.map((item: ItemLatestNewsProps) => (
            <ItemLatestNews {...item} />
          ))}
        </Container>
      </DesktopWrapper>
      <MobileWrapper>
        {latestNews.map((item: ItemLatestNewsProps) => (
          <ItemLatestNews {...item} />
        ))}
      </MobileWrapper>
    </>
  );
};
