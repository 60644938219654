import styled from 'styled-components';

import {
  getMediaQuery,
  getUnitAsPixels,
  ScreenSize,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Content = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 5;
  }
`;

export const ExpandingList = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: 7 / span 6;
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}

  & > p:not(:last-child) {
    margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;
