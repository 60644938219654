import React from 'react';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { Media } from '../../common/media';

import { MediaSingle, MediaColumn } from './moduleImage.styles';
import { ModuleImageProps } from './moduleImage.types';

export const ModuleImage = ({
  theme,
  imageFullWidthMedia,
  imageMedia,
  hasSpace,
  mediaControls,
}: ModuleImageProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
      >
        {imageFullWidthMedia.length === 1 ? (
          <MediaSingle>
            {imageFullWidthMedia.map((asset) => (
              <Media media={asset} fullWidth mediaControls={mediaControls} />
            ))}
          </MediaSingle>
        ) : (
          <>
            {imageMedia.map((asset) => (
              <MediaColumn>
                <Media media={asset} mediaControls={mediaControls} />
              </MediaColumn>
            ))}
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};
