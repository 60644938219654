import React from 'react';

import Download from '../../../../assets/icons/download.inline.svg';
import { getDate } from '../../../../utils/getDate';

import {
  FileName,
  Container,
  DownloadIconWrapper,
  DownloadLink,
  Title,
  Date,
  Content,
  FileNameWrapper,
} from './itemDownloadList.styles';
import { ItemDownloadListProps } from './itemDownloadList.types';

export const ItemDownloadList: React.FC<ItemDownloadListProps> = ({
  title,
  text,
  file,
  date,
}) => {
  return (
    <Container isDate={!!date}>
      {date && <Date>{getDate(date)}</Date>}
      <Content>
        <FileNameWrapper>
          {title && <Title>{title}</Title>}
          <FileName>{text}</FileName>
        </FileNameWrapper>
        <DownloadIconWrapper>
          <Download />
        </DownloadIconWrapper>
      </Content>
      <DownloadLink href={`${file.url}?dl=${file.filename}`} target="_blank" />
    </Container>
  );
};
