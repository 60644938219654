import React from 'react';

import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { Markdown } from '../../common/markdown';

import {
  Column,
  Columns,
  Title,
  Text,
  Address,
  Email,
  EmailArrow,
} from './moduleContact.styles';
import { ModuleContactProps } from './moduleContact.types';

export const ModuleContact = ({ columns }: ModuleContactProps) => {
  return (
    <Container spaceBottom={VerticalSpacingVariant.XLARGE}>
      <Columns>
        {columns.map((column, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Column key={index}>
            <Title>
              <Markdown text={column.title} />
            </Title>
            {column.address ? (
              <Address>
                <Markdown text={column.address} />
              </Address>
            ) : null}
            {column.email ? (
              <Email href={`mailto:${column.email}`}>
                {column.email}
                <EmailArrow>↗</EmailArrow>
              </Email>
            ) : null}
            {column.phone ? <Text>{column.phone}</Text> : null}
          </Column>
        ))}
      </Columns>
    </Container>
  );
};
