import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { useLocale } from '../../../../context/locale';

import {
  Item,
  Title,
  Place,
  Arrow,
  ArrowWrapper,
} from './itemPositionsList.styles';
import { ItemPositionsListProps } from './itemPositionsList.types';

export const ItemPositionsList = ({
  title,
  location,
  slug,
}: ItemPositionsListProps) => {
  const locale = useLocale();

  return (
    <Item as={GatsbyLink} to={`/${locale}/careers/${slug}`}>
      <Title>{title}</Title>
      <Place>{location}</Place>
      <ArrowWrapper>
        <Arrow />
      </ArrowWrapper>
    </Item>
  );
};
