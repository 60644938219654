import styled from 'styled-components';

import { getColor, Color } from '../../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  getUnitAsPixels,
  getMediaQuery,
  ScreenSize,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Item = styled.div`
  border-top: 1px solid ${getColor(Color.GREY_02)};
  display: grid;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.SMALL,
    bottom: VerticalSpacingVariant.SMALL,
  })}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.MARGIN, {
    bottom: VerticalSpacingVariant.SMALL,
  })}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-template-columns: calc(25% + 5px) calc(75% - 5px);
  }
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels()};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: auto;
    margin-bottom: auto;
    padding-right: ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  margin-bottom: ${getUnitAsPixels()}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    width: auto;
  }
`;
