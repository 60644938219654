import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { useToggle } from '../../../../../hooks/useToggle';
import { Markdown } from '../../../../common/markdown';
import { Media } from '../../../../common/media';
import { ReadMore } from '../../../../common/readMore';
import { Toggler } from '../../toggler/toggler.styles';

import {
  Root,
  ItemRow,
  Heading,
  Subheading,
  Text,
  ItemOpenWrapper,
  ImageWrapper,
  ItemCloseIcon,
  ItemOpenIcon,
} from './itemExpandingList.styles';
import { ItemExpandingListProps } from './itemExpandingList.types';

export const ItemExpandingList = ({
  heading,
  subheading,
  text,
  links,
  _media,
  mediaControls,
}: ItemExpandingListProps) => {
  const [isExpanded, toggleIsExpanded] = useToggle();

  const [height, setHeight] = useState(0);

  const toggleHeight = () => {
    setHeight((currHeight) => {
      return currHeight === 0 ? 'auto' : 0;
    });
  };

  const toggleExpanded = () => {
    toggleIsExpanded();
    toggleHeight();
  };

  return (
    <Root>
      <ItemRow onClick={toggleExpanded}>
        <Heading>{heading}</Heading>
        <Subheading>{subheading}</Subheading>
        <Toggler>
          {isExpanded ? (
            <ItemCloseIcon />
          ) : (
            <ItemOpenWrapper>
              <ItemOpenIcon />
            </ItemOpenWrapper>
          )}
        </Toggler>
      </ItemRow>
      <AnimateHeight duration={600} height={height}>
        <Text>
          <Markdown text={text} />
        </Text>
        {_media && (
          <ImageWrapper>
            <Media media={_media} mediaControls={mediaControls} />
          </ImageWrapper>
        )}
        <ReadMore links={links} gutterBottom />
      </AnimateHeight>
    </Root>
  );
};
