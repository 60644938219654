import React from 'react';

import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { Video } from '../../common/video';

import { MediaSingle } from './moduleVideo.styles';
import { ModuleVideoProps } from './moduleVideo.types';

export const ModuleVideo = ({ hasSpace, video }: ModuleVideoProps) => {
  return (
    <Container
      spaceTop={
        hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
      }
    >
      <MediaSingle>
        <Video
          src={video.video.mp4Url}
          fullWidth
          mediaControls
          initialDuration={video.video.duration}
        />
      </MediaSingle>
    </Container>
  );
};
