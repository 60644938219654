import React from 'react';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';

import { Text } from './moduleIntro.styles';
import { ModuleIntroProps } from './moduleIntro.types';

export const ModuleIntro = ({ text, theme }: ModuleIntroProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container spaceBottom={VerticalSpacingVariant.LARGE}>
        <Text>{text}</Text>
      </Container>
    </ThemeProvider>
  );
};
