import styled from 'styled-components';

import ArrowIcon from '../../../../assets/icons/arrow.inline.svg';
import { getColor, Color } from '../../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  getUnitAsPixels,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Item = styled.div`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: 45% 45% 10%;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.SMALL,
    bottom: VerticalSpacingVariant.SMALL,
  })}
  border-top: 1px solid ${getColor(Color.GREY_02)};

  &:last-child {
    border-bottom: 1px solid ${getColor(Color.GREY_02)};
  }

  :hover {
    border-top: 1px solid ${getColor(Color.RED)};
    div {
      color: ${getColor(Color.RED)};
    }
    path {
      fill: ${getColor(Color.RED)};
    }
  }

  & > a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
`;

export const Place = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: ${getUnitAsPixels({ multiplier: 0.5 })};
`;

export const Arrow = styled(ArrowIcon)`
  ${getTypography(Type.BODY_REGULAR_ICON)};
`;

export const Link = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;
