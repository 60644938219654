import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  getMediaQuery,
  ScreenSize,
  getUnitAsPixels,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Content = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 5;
  }
`;

export const Heading = styled.h2`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}

  & > p:not(:last-child) {
    margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;

export const MediaWrapper = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: 7 / span 6;
  }
`;
