import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { parseLinks } from '../../../common/content';

import { Link, RedArrowIcon, BlackArrowIcon } from './readMore.styles';
import { ReadMoreProps } from './readMore.types';

export const ReadMore = ({
  links,
  gutterBottom = false,
  gutterTop = false,
  asButton = false,
}: ReadMoreProps) => {
  const link = parseLinks(links)[0] || null;

  return link ? (
    link.isInternal ? (
      <Link
        as={GatsbyLink}
        to={link.url}
        gutterBottom={gutterBottom}
        gutterTop={gutterTop}
        asButton={asButton}
      >
        {link.label}
        {!asButton && <RedArrowIcon />}
        {!!asButton && <BlackArrowIcon />}
      </Link>
    ) : (
      <Link
        href={link.url}
        gutterBottom={gutterBottom}
        gutterTop={gutterTop}
        asButton={asButton}
      >
        {link.label}
        {!asButton && <RedArrowIcon />}
        {!!asButton && <BlackArrowIcon />}
      </Link>
    )
  ) : null;
};
