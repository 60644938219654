import React from 'react';

import { getTypeName, getTheme } from '../../../common/content';
import { ModuleArticleShareBlock } from '../../blocks/moduleArticleShareBlock';
import { ModuleArticlesBlock } from '../../blocks/moduleArticlesBlock';
import { ModuleContact } from '../../blocks/moduleContact';
import { ModuleDownloadList } from '../../blocks/moduleDownloadList';
import { ModuleExpandingList } from '../../blocks/moduleExpandingList';
import { ModuleGallery } from '../../blocks/moduleGallery';
import { ModuleHeading } from '../../blocks/moduleHeading';
import { ModuleHero } from '../../blocks/moduleHero';
import { ModuleImage } from '../../blocks/moduleImage';
import { ModuleIntro } from '../../blocks/moduleIntro';
import { ModuleLatestNews } from '../../blocks/moduleLatestNews';
import { ModuleLatestUpdateList } from '../../blocks/moduleLatestUpdateList';
import { ModuleLogoBlock } from '../../blocks/moduleLogoBlock';
import { ModuleNews } from '../../blocks/moduleNews';
import { ModuleNumberedList } from '../../blocks/moduleNumberedList';
import { ModulePositionsList } from '../../blocks/modulePositionsList';
import { ModuleQuote } from '../../blocks/moduleQuote';
import { ModuleText } from '../../blocks/moduleText';
import { ModuleTextAndImage } from '../../blocks/moduleTextAndImage';
import { ModuleUpdateList } from '../../blocks/moduleUpdateList';
import { ModuleValuesList } from '../../blocks/moduleValuesList';
import { ModuleVideo } from '../../blocks/moduleVideo';

import { BodyModularProps } from './bodyModular.types';

export const BodyModular = ({ content }: BodyModularProps) => {
  let previousTheme: string | undefined;
  let theme: string | undefined;

  if (content) {
    return (
      <>
        {content.map((block: any) => {
          const type = getTypeName(block);

          previousTheme = theme;
          theme = getTheme(block);
          switch (type) {
            case 'module_text_and_image':
              return (
                <ModuleTextAndImage
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_text':
              return (
                <ModuleText {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_image':
              return (
                <ModuleImage {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_video':
              return (
                <ModuleVideo {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_heading':
              return (
                <ModuleHeading
                  {...block}
                  hasDivider={
                    previousTheme === theme || previousTheme === undefined
                  }
                  innerPageTitle={theme === 'Inner-H1-Title'}
                />
              );
            case 'module_gallery':
              return (
                <ModuleGallery {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_expanding_list':
              return (
                <ModuleExpandingList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_hero':
              return <ModuleHero {...block} />;
            case 'module_numbered_list':
              return (
                <ModuleNumberedList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_quote':
              return (
                <ModuleQuote {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_values_list':
              return (
                <ModuleValuesList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_positions_list':
              return (
                <ModulePositionsList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_latest_update_list':
              return (
                <ModuleLatestUpdateList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_download_list':
              return (
                <ModuleDownloadList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_update_list':
              return (
                <ModuleUpdateList
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_news':
              return (
                <ModuleNews {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_latest_news':
              return (
                <ModuleLatestNews
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_logo_block':
              return (
                <ModuleLogoBlock
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_contact':
              return (
                <ModuleContact {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_articles_block':
              return (
                <ModuleArticlesBlock
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            case 'module_intro':
              return (
                <ModuleIntro {...block} hasSpace={previousTheme !== theme} />
              );
            case 'module_article_share_block':
              return (
                <ModuleArticleShareBlock
                  {...block}
                  hasSpace={previousTheme !== theme}
                />
              );
            default:
              return null;
          }

          return null;
        })}
      </>
    );
  }

  return null;
};
