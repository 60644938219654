import React, { useEffect, useRef, useState } from 'react';

import { useToggle } from '../../../hooks/useToggle';

import {
  VideoWrapper,
  Video as VideoComponent,
  PlayingBlock,
  Timer,
} from './video.styles';
import { VideoProps } from './video.types';

export const Video = ({
  autoPlay,
  className,
  src,
  muted,
  noRadius,
  initialDuration,
  fullWidth,
  isHero,
  mediaControls,
}: VideoProps) => {
  const [durationLeft, setDurationLeft] = useState<number | null>(
    initialDuration ?? null,
  );
  const [isPlaying, togglePlaying] = useToggle(autoPlay);
  const ref = useRef<HTMLVideoElement | null>(null);
  const videoClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const video = ref.current!;

    if (isHero || !mediaControls) return;

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    togglePlaying(togglePlaying);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const video = ref.current!;

    const loop = () => {
      video.currentTime = 0.1;
      video.play();
    };

    const updateCountdown = () => {
      setDurationLeft(Math.round(video.duration - video.currentTime));
    };

    video.addEventListener('ended', loop);
    video.addEventListener('timeupdate', updateCountdown);

    return () => {
      video.removeEventListener('ended', loop);
      video.removeEventListener('timeupdate', updateCountdown);
    };
  }, []);

  return (
    <VideoWrapper
      className={className}
      noRadius={noRadius ?? false}
      fullWidth={fullWidth ?? false}
      onClick={videoClick}
      isHero={isHero ?? false}
    >
      <VideoComponent
        ref={ref}
        controls={false}
        playsInline
        autoPlay={autoPlay}
        muted={muted}
        preload="metadata"
      >
        <source src={`${src}#t=0.1`} type="video/mp4" />
      </VideoComponent>
      {!isHero && mediaControls && (
        <PlayingBlock>{isPlaying ? 'Pause' : 'Play'}</PlayingBlock>
      )}
      {initialDuration && durationLeft !== null ? (
        <Timer>
          {Math.floor(durationLeft / 60)}:
          {durationLeft % 60 < 10 ? `0${durationLeft % 60}` : durationLeft % 60}
        </Timer>
      ) : null}
    </VideoWrapper>
  );
};
