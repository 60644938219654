import styled from 'styled-components';

import { getColor, Color } from '../../../../theme/color';
import {
  getUnitAsPixels,
  getMediaQuery,
  ScreenSize,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Root = styled.div`
  border-top: 1px solid ${getColor(Color.GREY_02)};

  ${getMediaQuery(ScreenSize.LARGE)} {
    display: grid;
    grid-template-columns: calc(25% + 5px) calc(75% - 5px);
  }
`;

export const Index = styled.div`
  position: relative;
  ${getTypography(Type.HEADING_2)}
  padding-top: ${getUnitAsPixels({ multiplier: 2 })};
  margin-bottom: ${getUnitAsPixels({ multiplier: 5 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 7 })};
  }
`;

export const Info = styled.div`
  padding-top: ${getUnitAsPixels({ multiplier: 2 })};
  padding-bottom: ${getUnitAsPixels({ multiplier: 5 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    padding-bottom: ${getUnitAsPixels({ multiplier: 7 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding-top: ${getUnitAsPixels({ multiplier: 3 })};
    padding-bottom: ${getUnitAsPixels({ multiplier: 6 })};
  }
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels()};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
`;
