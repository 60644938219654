import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import { borderRadius, getUnitAsPixels } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const VideoWrapper = styled.div<{
  noRadius: boolean;
  fullWidth: boolean;
  isHero: boolean;
}>`
  ${({ isHero }) => isHero && `pointer-events: none;`}
  position: relative;
  overflow: hidden;
  padding-top: ${({ fullWidth }) => (fullWidth ? '56.3%' : '100%')};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ noRadius }) => (noRadius ? 0 : `${borderRadius}`)};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    mix-blend-mode: normal;
    transform: rotate(-180deg);
    z-index: 1;
    opacity: 0.8;
    transition: opacity 0.3s ease-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;

export const Video = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PlayingBlock = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  color: ${getColor(Color.WHITE)};
  ${getTypography(Type.HEADING_2)}
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  margin-left: ${getUnitAsPixels({ multiplier: 3 })};
  cursor: pointer;
  z-index: 2;
`;

export const Timer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  color: ${getColor(Color.WHITE)};
  ${getTypography(Type.HEADING_2)}
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  margin-right: ${getUnitAsPixels({ multiplier: 3 })};
  z-index: 2;
`;
