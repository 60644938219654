import React from 'react';
import { ThemeProvider } from 'styled-components';

import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';

import { ContentWrapper, Content, Text, Caption } from './moduleQuote.styles';
import { ModuleQuoteProps } from './moduleQuote.types';

export const ModuleQuote = ({ theme, text, caption }: ModuleQuoteProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={VerticalSpacingVariant.NONE}
        spaceBottom={VerticalSpacingVariant.NONE}
      >
        <ContentWrapper>
          <Content>
            <Text>
              “<br />
              {text}
            </Text>
            <Caption>{caption}</Caption>
          </Content>
        </ContentWrapper>
      </Container>
    </ThemeProvider>
  );
};
