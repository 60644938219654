import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { useToggle } from '../../../../../hooks/useToggle';
import { getNumber } from '../../../../../utils/getNumber';
import { Markdown } from '../../../../common/markdown';
import { Media } from '../../../../common/media';
import { ReadMore } from '../../../../common/readMore';
import { Toggler } from '../../toggler/toggler.styles';

import {
  Root,
  ItemRow,
  Index,
  Heading,
  Text,
  ReadMoreWrapper,
  ItemOpenWrapper,
  ImageWrapper,
  ItemCloseIcon,
  ItemOpenIcon,
} from './itemExpandingNumberedList.styles';
import { ItemExpandingNumberedListProps } from './itemExpandingNumberedList.types';

export const ItemExpandingNumberedList = ({
  heading,
  text,
  links,
  _media,
  index,
  mediaControls,
}: ItemExpandingNumberedListProps) => {
  const [isExpanded, toggleIsExpanded] = useToggle();

  const [height, setHeight] = useState(0);

  const toggleHeight = () => {
    setHeight((currHeight) => {
      return currHeight === 0 ? 'auto' : 0;
    });
  };

  const toggleExpanded = () => {
    toggleIsExpanded();
    toggleHeight();
  };

  return (
    <Root>
      <ItemRow onClick={toggleExpanded}>
        <Index>{getNumber(index + 1)}</Index>
        <Heading>{heading}</Heading>
        <Toggler>
          {isExpanded ? (
            <ItemCloseIcon />
          ) : (
            <ItemOpenWrapper>
              <ItemOpenIcon />
            </ItemOpenWrapper>
          )}
        </Toggler>
      </ItemRow>
      <AnimateHeight duration={600} height={height}>
        <Text>
          <Markdown text={text} />
        </Text>
        {_media && (
          <ImageWrapper>
            <Media media={_media} mediaControls={mediaControls} />
          </ImageWrapper>
        )}
        <ReadMoreWrapper>
          <ReadMore links={links} />
        </ReadMoreWrapper>
      </AnimateHeight>
    </Root>
  );
};
