import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import { useLocale } from '../../../../context/locale';
import { getDate } from '../../../../utils/getDate';
import { Image } from '../../../common/image';

import { Root, Date, Title, Link, Info } from './itemLatestNews.styles';
import { ItemLatestNewsProps } from './itemLatestNews.types';

export const ItemLatestNews = ({
  description,
  previewImage,
  date,
  slug,
}: ItemLatestNewsProps) => {
  const locale = useLocale();

  return (
    <Root>
      <Link as={GatsbyLink} to={`/${locale}/news/${slug}`}>
        <Info>
          <Date>{getDate(date)}</Date>
          <Title>{description}</Title>
        </Info>
        <Image asset={previewImage} />
      </Link>
    </Root>
  );
};
