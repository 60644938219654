import styled from 'styled-components';

import { fadeIn } from '../../../theme/animation';
import { getMediaQuery, ScreenSize } from '../../../theme/layout';

export const DownloadList = styled.div`
  grid-column: ${({ isFilters }: { isFilters: boolean; isDate: boolean }) =>
    isFilters ? 'span 8' : 'span 12'};

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: ${({ isDate }: { isDate: boolean }) =>
      isDate ? '5 / span 8' : '7 / span 6'};
  }
`;

export const Expanded = styled.div`
  animation: ${fadeIn} 1s ease;
`;

export const Col = styled.div`
  grid-column: span 4;
`;

export const ShowMoreContainer = styled.div`
  display: grid;
  & > div {
    margin-left: ${({ isDate }: { isDate: boolean }) =>
      isDate ? 'calc(25% + 5px)' : 'none'};
  }
`;
