import styled from 'styled-components';

import arrow_to_right from '../../../assets/icons/arrow_to_right.svg';
import { borderRadius } from '../../../theme/layout';

export const Gallery = styled.div`
  .gatsby-image-wrapper {
    border-radius: ${borderRadius};
    z-index: 1;
  }

  div[aria-hidden='true'] {
    border-radius: ${borderRadius};
  }

  .image-gallery-slides {
    border-radius: ${borderRadius};
  }

  &:hover {
    cursor: url(${arrow_to_right}) 60 55, move;
  }
`;
