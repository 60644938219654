import styled from 'styled-components';

import Email from '../../../assets/icons/email.inline.svg';
import Facebook from '../../../assets/icons/facebook.inline.svg';
import Twitter from '../../../assets/icons/twitter.inline.svg';
import { Color, getColor } from '../../../theme/color';
import {
  getMediaQuery,
  getUnitAsPixels,
  ScreenSize,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column: span 12;
  margin-bottom: ${getUnitAsPixels({ multiplier: 4 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    flex-wrap: nowrap;
    grid-column: 7 / span 6;
    margin-bottom: ${getUnitAsPixels({ multiplier: 6 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 9 })};
  }
`;

export const PdfWrapper = styled.a`
  display: inline-flex;
  align-items: center;
  padding: ${getUnitAsPixels({ multiplier: 2 })};
  margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  color: ${getColor(Color.BLACK)};
  ${getTypography(Type.BODY_SMALL)}
  text-decoration: none;
  background: ${getColor(Color.GREY_01)};
  cursor: pointer;
  white-space: nowrap;

  & > svg {
    margin-left: ${getUnitAsPixels({ multiplier: 4 })};
  }

  :hover {
    box-shadow: inset 0px -1px 0px 0px ${getColor(Color.RED)};

    & path {
      stroke: ${getColor(Color.RED)};
    }
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-right: ${getUnitAsPixels({ multiplier: 4 })};
    margin-bottom: 0;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-right: ${getUnitAsPixels({ multiplier: 12 })};
  }

  ${getMediaQuery(ScreenSize.SMALL, ScreenSize.MEDIUM)} {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2px;

  & > a {
    margin-right: ${getUnitAsPixels({ multiplier: 3 })};
    padding: ${getUnitAsPixels({ multiplier: 0.5 })};

    &:hover > svg {
      & > path {
        fill: ${getColor(Color.RED)};
      }
    }
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding-top: 6px;
  }
`;

export const SocialText = styled.div`
  ${getTypography(Type.BODY_SMALL)}
  margin-right: ${getUnitAsPixels({ multiplier: 3 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-top: ${getUnitAsPixels({ multiplier: 1 })};
    padding-left: ${getUnitAsPixels({ multiplier: 0.5 })};
  }
`;

export const TwitterIcon = styled(Twitter)``;

export const FacebookIcon = styled(Facebook)``;

export const EmailIcon = styled(Email)``;
