import styled from 'styled-components';

import { getColor, Color } from '../../../theme/color';
import { getMediaQuery, ScreenSize, borderRadius } from '../../../theme/layout';

export const Items3Col = styled.div`
  position: relative;
  padding-top: 60%;
  grid-column: span 12;
  background-color: ${getColor(Color.GREY_02)};
  border-radius: ${borderRadius};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 4;
  }
`;

export const Items4Col = styled.div`
  position: relative;
  padding-top: 60%;
  grid-column: span 12;
  background-color: ${getColor(Color.GREY_02)};
  border-radius: ${borderRadius};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 3;
  }
`;

export const ImageWrapper = styled.div``;
