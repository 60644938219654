import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import { useLocale } from '../../../context/locale';
import { useFilters } from '../../../hooks/useFilters';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { Filters } from '../../common/filters';

import { ItemPositionsList } from './itemPositionsList';
import { Col } from './modulePositionsList.styles';
import { ModulePositionsListProps } from './modulePositionsList.types';

export const ModulePositionsList = ({
  theme,
  hasSpace,
}: ModulePositionsListProps) => {
  const data = useStaticQuery(graphql`
    query PositionListQuery {
      jobs: allDatoCmsCareersJob(filter: { slug: { ne: null } }) {
        nodes {
          locale
          id
          slug
          title
          location
          category
        }
      }
    }
  `);
  const locale = useLocale();
  const jobs = get(data, 'jobs.nodes').filter((n) => n.locale === locale);
  const { filters, activeFilter, filteredItems, chageFilter } = useFilters(
    'All',
    jobs,
  );

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
      >
        <Col>
          <Filters
            filters={filters}
            activeFilter={activeFilter}
            onChange={chageFilter}
          />
        </Col>
        <Col>
          {filteredItems.map((item) => (
            <ItemPositionsList {...item} />
          ))}
        </Col>
      </Container>
    </ThemeProvider>
  );
};
