import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
  borderRadius,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const ContentWrapper = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-column: span 12;
  padding: ${getUnitAsPixels({ multiplier: 20 })}
    ${getUnitAsPixels({ multiplier: 2 })} ${getUnitAsPixels({ multiplier: 2 })};
  background-color: ${getColor(Color.BLACK)};
  margin-left: -${getUnitAsPixels({ multiplier: 2 })};
  margin-right: -${getUnitAsPixels({ multiplier: 2 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    padding: ${getUnitAsPixels({ multiplier: 45 })}
      ${getUnitAsPixels({ multiplier: 3 })}
      ${getUnitAsPixels({ multiplier: 3 })};
    margin-left: -${getUnitAsPixels({ multiplier: 3 })};
    margin-right: -${getUnitAsPixels({ multiplier: 3 })};
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    padding: ${getUnitAsPixels({ multiplier: 3 })};
    margin: ${getUnitAsPixels({ multiplier: 3 })} 0;
    height: 100vh;
    max-height: ${getUnitAsPixels({ multiplier: 120 })};
    border-radius: ${borderRadius};
  }
`;

export const Content = styled.div`
  width: 100%;

  ${getMediaQuery(ScreenSize.LARGE)} {
    width: calc(75% - ${getUnitAsPixels({ multiplier: 3 })});
  }
`;

export const Text = styled.p`
  ${getTypography(Type.QUOTE)};
  color: ${getColor(Color.WHITE)};
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const Caption = styled.figcaption`
  ${getTypography(Type.CAPTION)};
  color: ${getColor(Color.WHITE)};
`;
