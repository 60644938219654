import styled from 'styled-components';

import { getColor, Color } from '../../../theme/color';
import { getTypography, Type } from '../../../theme/typography';

export const Wrapper = styled.div``;

export const Col = styled.div`
  grid-column: span 6;
`;

export const Filter = styled.div`
  color: ${({ active }: { active: boolean }) =>
    getColor(active ? Color.RED : Color.BLACK)};
  cursor: pointer;
  ${getTypography(Type.HEADING_3)}
  :hover {
    color: ${getColor(Color.RED)};
  }
`;
