import styled from 'styled-components';

import { ScreenSize, getMediaQuery } from '../../../theme/layout';

export const MediaSingle = styled.div`
  grid-column: span 12;
`;

export const MediaGallery = styled.div`
  grid-column: span 12;
`;

export const MediaColumn = styled.div`
  grid-column: span 12;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 6;
  }
`;
