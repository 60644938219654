import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { DatoCmsNewsArticle } from '../../../common/types/graphql';
import { useLocale } from '../../../context/locale';
import { Theme } from '../../../theme/color';
import { ModuleDownloadList } from '../moduleDownloadList';

interface Props {
  theme: Theme;
  collapsible: boolean;
  hasSpace?: boolean;
}

export const ModuleLatestUpdateList: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query LatestNewsUpdates {
      latestUpdates: allDatoCmsItemUpdateList(
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          id
          title
          date
          locale
          text
          file {
            url
            filename
          }
        }
      }
    }
  `);
  const locale = useLocale();
  const latestUpdates = data.latestUpdates.nodes
    .filter((n: DatoCmsNewsArticle) => n.locale === locale)
    .slice(0, 5);

  return (
    <ModuleDownloadList
      items={latestUpdates}
      collapsible={props.collapsible}
      theme={props.theme}
      hasSpace={props.hasSpace}
    />
  );
};
