import styled from 'styled-components';

import { Color, getColor } from '../../../../theme/color';
import {
  getVerticalSpacingAsStyle,
  VerticalSpacingType,
  VerticalSpacingVariant,
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Root = styled.div`
  position: relative;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.SMALL,
    bottom: VerticalSpacingVariant.MEDIUM,
  })}
  border-top: 1px solid ${getColor(Color.GREY_02)};
  cursor: pointer;
  transition: border-color 0.4s ease;

  &:hover {
    border-color: ${getColor(Color.RED)};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    &:nth-child(2n + 1) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        right: calc(-100% - ${getUnitAsPixels({ multiplier: 3 })});
        width: calc(100% + ${getUnitAsPixels({ multiplier: 3 })});
        height: 1px;
        border-top: 1px solid ${getColor(Color.GREY_02)};
      }
    }
  }
`;

export const Info = styled.div``;

export const Date = styled.div`
  ${getTypography(Type.BODY_REGULAR)};
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels()};
  transition: color 0.4s ease;

  ${Root}:hover & {
    color: ${getColor(Color.RED)};
  }
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)};
  margin-bottom: ${getUnitAsPixels({ multiplier: 3 })};
  height: auto;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    height: 70px;
    overflow: hidden;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    height: 86px;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    height: 96px;
  }
`;

export const Link = styled.a`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
`;
