import React from 'react';
import { ThemeProvider } from 'styled-components';

import { DatoCmsItemValuesList } from '../../../common/types/graphql';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';

import { ItemValuesList } from './itemValuesList';
import { ValuesList } from './moduleValuesList.styles';
import { ModuleValuesListProps } from './moduleValuesList.types';

export const ModuleValuesList = ({
  hasSpace,
  theme,
  items,
}: ModuleValuesListProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace
            ? VerticalSpacingVariant.SMALL
            : VerticalSpacingVariant.MEDIUM
        }
        spaceBottom={
          hasSpace
            ? VerticalSpacingVariant.SMALL
            : VerticalSpacingVariant.MEDIUM
        }
      >
        <ValuesList>
          {items.map((item: DatoCmsItemValuesList) => (
            <ItemValuesList {...item} key={item.id} />
          ))}
        </ValuesList>
      </Container>
    </ThemeProvider>
  );
};
