import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { ContainerDisplay } from '../../common/container/container.types';
import { Gallery as CommonGallery } from '../../common/gallery';
import { Image } from '../../common/image';

import { Gallery } from './moduleGallery.styles';
import { ModuleGalleryProps } from './moduleGallery.types';

const ImageWrapper = styled.div`
  user-select: none;
`;

export const ModuleGallery = ({
  theme,
  media,
  hasSpace,
}: ModuleGalleryProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        display={ContainerDisplay.BLOCK}
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
      >
        <Gallery>
          <CommonGallery autoPlay autoPlayInterval={5000}>
            {media.map((asset) => (
              <ImageWrapper>
                <Image asset={asset} noRed />
              </ImageWrapper>
            ))}
          </CommonGallery>
        </Gallery>
      </Container>
    </ThemeProvider>
  );
};
