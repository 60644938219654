import React from 'react';

import { parseLinks } from '../../../../common/content';
import { Image } from '../../../common/image';

import { ImageWrapper, Link, LinkLabel } from './itemLogoBlock.styles';
import { ItemLogoBlockProps } from './itemLogoBlock.types';

export const ItemLogoBlock = ({ logo, link }: ItemLogoBlockProps) => {
  const parseLink = parseLinks(link)[0] || null;

  return (
    <>
      <ImageWrapper>
        <Image asset={logo} noRed />
      </ImageWrapper>
      {!!link.length && (
        <Link href={link[0].url} target="_blank">
          <LinkLabel>{parseLink.label}</LinkLabel>
        </Link>
      )}
    </>
  );
};
