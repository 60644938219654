import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  getMediaQuery,
  ScreenSize,
  getUnitAsPixels,
  screenSizeMax,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const MediaWrapper = styled.div`
  grid-column: span 12;
  position: relative;
  margin-top: -${getUnitAsPixels({ multiplier: 7 })};
  height: 135vh;

  div[data-gatsby-image-wrapper] {
    height: 100%;
    margin-bottom: 0;
  }

  & > div[class^='video'] {
    padding-top: 0 !important;
    height: 100%;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin-top: -${getUnitAsPixels({ multiplier: 8 })};
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 135vh;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    mix-blend-mode: normal;
    transform: rotate(-180deg);
    z-index: 2;
  }
`;

export const Title = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: ${screenSizeMax};
  height: 100%;
  padding: 0 ${getUnitAsPixels({ multiplier: 2 })}
    ${getUnitAsPixels({ multiplier: 2 })};
  margin: -135vh auto 0;
  z-index: 3;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    margin: -135vh auto 0;
    padding: 0 ${getUnitAsPixels({ multiplier: 3 })}
      ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const Text = styled.h1`
  position: sticky;
  bottom: ${getUnitAsPixels({ multiplier: 2 })};
  ${getTypography(Type.HEADING_HERO)}
  grid-column: span 12;
  letter-spacing: -0.03em;
  color: ${getColor(Color.WHITE)};

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    bottom: ${getUnitAsPixels({ multiplier: 3 })};
  }
`;
