import React from 'react';

import { DatoCmsItemLogoBlock } from '../../../common/types/graphql';
import { Container } from '../../common/container';

import { ItemLogoBlock } from './itemLogoBlock';
import { Items3Col, Items4Col } from './moduleLogoBlock.styles';
import { ModuleLogoBlockProps } from './moduleLogoBlock.types';

export const ModuleLogoBlock = ({ items }: ModuleLogoBlockProps) => {
  return (
    <Container>
      {items.length <= 6 ? (
        <>
          {items.map((item: DatoCmsItemLogoBlock) => {
            return (
              <Items3Col>
                <ItemLogoBlock
                  {...(item as DatoCmsItemLogoBlock)}
                  key={item.id}
                />
              </Items3Col>
            );
          })}
        </>
      ) : (
        <>
          {items.map((item: DatoCmsItemLogoBlock) => {
            return (
              <Items4Col>
                <ItemLogoBlock
                  {...(item as DatoCmsItemLogoBlock)}
                  key={item.id}
                />
              </Items4Col>
            );
          })}
        </>
      )}
    </Container>
  );
};
