import React from 'react';
import { ThemeProvider } from 'styled-components';

import { getTypeName } from '../../../common/content';
import { plainTextToHTML } from '../../../common/string';
import {
  DatoCmsItemExpandingNumberedList,
  DatoCmsItemExpandingList,
} from '../../../common/types/graphql';
import { getTheme } from '../../../theme/color';
import { VerticalSpacingVariant } from '../../../theme/layout';
import { Container } from '../../common/container';
import { ReadMore } from '../../common/readMore';

import { ItemExpandingList } from './items/itemExpandingList';
import { ItemExpandingNumberedList } from './items/itemExpandingNumberedList';
import { Content, ExpandingList, Text } from './moduleExpandingList.styles';
import { ModuleExpandingNumberedListProps } from './moduleExpandingList.types';

export const ModuleExpandingList = ({
  theme,
  text,
  items,
  link,
  hasSpace,
}: ModuleExpandingNumberedListProps) => {
  return (
    <ThemeProvider theme={getTheme(theme)}>
      <Container
        spaceTop={
          hasSpace ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.NONE
        }
        spaceBottom={
          text ? VerticalSpacingVariant.SMALL : VerticalSpacingVariant.MEDIUM
        }
      >
        <Content>
          {text && <Text>{plainTextToHTML(text)}</Text>}
          <ReadMore links={link} gutterBottom gutterTop />
        </Content>
        <ExpandingList>
          {items.map(
            (
              item: DatoCmsItemExpandingList | DatoCmsItemExpandingNumberedList,
              index: number,
            ) => {
              const type = getTypeName(item);

              switch (type) {
                case 'item_expanding_list':
                  return (
                    <ItemExpandingList
                      {...(item as DatoCmsItemExpandingList)}
                      key={item.id}
                    />
                  );
                case 'item_expanding_numbered_list':
                  return (
                    <ItemExpandingNumberedList
                      {...(item as DatoCmsItemExpandingNumberedList)}
                      key={item.id}
                      index={index}
                    />
                  );
                default:
                  return null;
              }
            },
          )}
        </ExpandingList>
      </Container>
    </ThemeProvider>
  );
};
