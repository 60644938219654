import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const Markdown = ({ text }: { text: string }) => {
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} className="markdown">
      {text}
    </ReactMarkdown>
  );
};
