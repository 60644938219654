import React from 'react';

import { getDate } from '../../../utils/getDate';
import { Image } from '../../common/image';

import {
  TitleBlock,
  TextTitleWrapper,
  TextTitle,
  SubTitle,
  Title,
  ColorMask,
} from './moduleArticlesBlock.styles';
import { ModuleArticlesBlockProps } from './moduleArticlesBlock.types';

export const ModuleArticlesBlock = ({
  date,
  title,
  titleType,
  image,
}: ModuleArticlesBlockProps) => {
  return (
    <TitleBlock isImage={!!image}>
      {image ? <Image asset={image} noRadius /> : <ColorMask />}
      <TextTitleWrapper>
        <TextTitle>
          <SubTitle>
            <span>{getDate(date)}</span>
            <span>{titleType}</span>
          </SubTitle>
          <Title>{title}</Title>
        </TextTitle>
      </TextTitleWrapper>
    </TitleBlock>
  );
};
