import styled from 'styled-components';

import { Color, getColor } from '../../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
} from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Root = styled.div`
  width: calc(83.4% - ${getUnitAsPixels({ multiplier: 2 })});
  display: inline-block;
  white-space: break-spaces;

  &:not(:last-child) {
    margin-right: ${getUnitAsPixels({ multiplier: 2 })};
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 4;
    width: 100%;
  }
`;

export const Info = styled.div``;

export const Date = styled.div`
  ${getTypography(Type.BODY_REGULAR)};
  color: ${getColor(Color.GREY_03)};
  margin-bottom: ${getUnitAsPixels()};
  transition: color 0.4s ease;

  ${Root}:hover & {
    color: ${getColor(Color.RED)};
  }
`;

export const Title = styled.div`
  ${getTypography(Type.BODY_REGULAR)};
  margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
  height: 70px;
  overflow: hidden;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    height: 86px;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    height: 96px;
  }
`;

export const Link = styled.a`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
`;
