import React from 'react';

import { getNumber } from '../../../../utils/getNumber';
import { ReadMore } from '../../../common/readMore';

import { Root, Index, Title, Text, Info } from './itemNumberedList.styles';
import { ItemNumberedListProps } from './itemNumberedList.types';

export const ItemNumberedList = ({
  heading,
  links,
  text,
  idx,
}: ItemNumberedListProps) => {
  return (
    <Root>
      <Index>{getNumber(idx + 1)}</Index>
      <Info>
        <Title>{heading}</Title>
        <Text>{text}</Text>
        <ReadMore links={links} gutterTop />
      </Info>
    </Root>
  );
};
