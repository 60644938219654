import styled from 'styled-components';

import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
} from '../../../theme/layout';

export const DesktopWrapper = styled.div`
  display: none;
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    display: grid;
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  margin: 0 ${getUnitAsPixels({ multiplier: 2 })}
    ${getUnitAsPixels({ multiplier: 2 })};
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    clear: both;
  }

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    display: none;
  }
`;
